<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-21 17:10:40
 * @ Description: Component displaying jobs on the landing browse page.
 -->

<template>
    <v-card
        :height="$vuetify.breakpoint.mdAndUp ? '496px' : ''"
        width="100%"
        max-width="280px"
        class="rounded-lg py-6 px-3 mx-auto"
    >
        <v-row
            align="center"
        >
            <!-- Job Banner -->
            <v-col
                cols="12"
                style="position: relative;"
                class="pa-2"
            >
                <div
                    style="height: 83px; display: flex; align-items: center;"
                >
                    <v-img
                        :src="item.banner ? item.banner : '/general/FixerCompanyIcon.png'"
                        max-height="80px"
                        max-width="80px"
                        :height="$vuetify.breakpoint.mdAndUp && item.banner ? '80px' : $vuetify.breakpoint.mdAndUp ? '50px' : ''"
                        :width="$vuetify.breakpoint.mdAndUp && item.banner ? '80px' : $vuetify.breakpoint.mdAndUp ? '50px' : ''"
                        contain
                        class="mx-auto"
                    />
                </div>
            </v-col>
            <!-- Job Banner -->

            <!-- Job Details -->
            <v-col
                cols="12"
                style="color: #8F9BB3;"
                class="os-10-r text-center py-0 text-truncate"
            >
                {{ item.company_name }}
            </v-col>
            <v-col
                cols="12"
                class="os-16-sb two-lines text-center"
                style="height: 60px; max-height: 60px;"
            >
                {{ item.name }}
            </v-col>
            <v-col
                cols="12"
                class="os-12-r four-lines text-center"
                style="color: #8F9BB3; height: 83px; max-height: 83px;"
                v-html="item.description"
                v-if="item.description"
            />
            <v-col
                cols="12"
                class="os-12-r four-lines text-center"
                style="color: #8F9BB3; height: 83px; max-height: 83px; display: flex; justify-content: center;"
                v-else
            >
                No description added
            </v-col>
            <v-col
                cols="12"
                class="pb-0 pt-6"
                style="position: relative;"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <v-divider />
                <span
                    class="os-12-b"
                    style="position: absolute; top: 0; right: 12px; color: #8F9BB3; cursor: pointer;"
                    @click="readMore(item)"
                >
                    Read more...
                </span>
            </v-col>
            <v-col
                cols="12"
                v-if="$vuetify.breakpoint.mdAndUp"
            >
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Industry:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ item.industry_name }}
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Title:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ item.job_title_name }}
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Location:
                </div>
                <div
                    class="os-12-sb text-truncate"
                    style="color: #2E567A;"
                >
                    <span v-if="item.mode_id === 1">-</span>
                    <span v-else-if="item.city && item.country">{{ item.city }} - {{ item.country }}</span>
                    <span v-else-if="item.state && item.country">{{ item.state }} - {{ item.country }}</span>
                    <span v-else-if="item.country">{{ item.country }}</span>
                    <span v-else>No address added</span>
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Type:
                </div>
                <div
                    class="os-12-sb"
                    style="color: #2E567A;"
                >
                    {{ item.type_name }}
                </div>
                <div
                    class="os-10-r"
                    style="color: #8F9BB3;"
                >
                    Mode:
                </div>
                <div
                    class="os-12-sb text-truncate"
                    style="color: #2E567A;"
                >
                    {{ item.mode_name }}
                </div>
            </v-col>
            <!-- <v-col
                cols="12"
                class="pb-0 py-md-0"
            >
                <v-divider />
            </v-col> -->
            <v-col
                cols="12"
                class="py-0 text-right"
                v-if="$vuetify.breakpoint.smAndDown"
            >
                <span
                    class="os-12-b"
                    style="color: #8F9BB3; cursor: pointer;"
                    @click="readMore(item)"
                >
                    Read more...
                </span>
            </v-col>
            <!-- Job Details -->
        </v-row>
    </v-card>
</template>
<script>
    export default {
        name: 'WorkJobGeneralLandingCardComponent',

        props: {
            item: {
                type: Object,
                required: true
            }
        },
        
        methods: {
            readMore (job) {
                this.$emit('readMore', job)
            }
        }
    }
</script>
<style scoped>
    .four-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        white-space: normal;
    }

    .two-lines {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
    }
</style>
